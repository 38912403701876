import React from 'react';
import HeaderSection from '../layout/pages/home/HeaderSection';
import CompanyStory from '../layout/text/CompanyStory';
import Cards from '../layout/cards/Cards';
import CardsHorizontal from '../layout/cards/CardsHorizontal';
import { importAll, importAllArray } from 'images';

import {entities} from 'entities.js';
import Gallery from 'components/layout/media/Gallery';
import { Container, ContainerContent, ContainerWrapper, ContainerGroup } from 'components/layout/containers/Containers';
import { ArrayToHTMLList } from 'components/tools/arrays';
import { P } from 'styling/Text';
import { List } from 'components/layout/text/TextComponents';


const story = undefined;
const title = `Assemblage`;

export default function Assembly () {
	let _video = `${process.env.PUBLIC_URL}/videos/video-1.mp4`;
	let _image = `${process.env.PUBLIC_URL}/images/portfolio/layout/pages/home/20210526_121644.jpg`;

	const _imageArray = importAllArray(
		require.context('../../../public/images/portfolio/layout/pages/assembly', true, /^(?!.*\/not-used\/).*\/.*.\.(JPG|png|jpe?g|svg)$/)
        ,'images/portfolio/layout/pages/assembly'
	);

    let _montagetechnieken = [
        'Mechanisch',
        'Pneumatisch',
    ];

    let _disciplines = [
        'Reinigen (o.a. ultrasoon)',
        'Testen/valideren',
        'Geconditioneerde meetruimte'
    ];

    return (
        <>
            <div className="page">
                {/* <HeaderSection video={'/videos/video-1.mp4'} showInfo={true} logo={true} /> */}
                {/* <HeaderSection video={_media} showInfo={true} logo={true} /> */}
                {/* <HeaderSection image={_image} showInfo={true} logo={true} /> */}
                <HeaderSection title={`${title}`} mediaHeight={'450px'} imageArray={_imageArray} showInfo={false} logo={false} />

                <div className="page-content">
                    <CompanyStory title={`${title}`}>
                        <P>
                            Naast het fabriceren, tekenen en de engineering van uw product(en) kunnen wij klanten volledig ontzorgen door de onderdelen samen te stellen in onze ‘schone’ assemblageruimte.
                        </P>
                        <P>
                            Wij kunnen daarbij ook voorzien in het nodige tekenwerk (exploded views), BOM-lijsten en stappenplan.
                        </P>
                        <P>
                            Doordat wij alle processen beheersen kunnen wij voor de klant alles heel goed vastleggen en begeleiden, ook nadat we hebben uitgeleverd, we dragen dan ook eigenaarschap over de processen die wij hebben uitgevoerd en dat reikt in dit geval vaak verder dan de uitgang.
                        </P>
                    </CompanyStory>

                    <ContainerGroup style={{alignItems: 'stretch'}}>
                        <ContainerWrapper className={'item'}>
                            <h1 style={{ width: '100%'}} className="container-title">Montagetechnieken:</h1>
                            <Container className='container-content-list'>
                                <ContainerContent>
                                    {/* <ul style={{padding: '15px'}}>
                                        {_montagetechnieken}
                                    </ul> */}

                                    <List style={{padding: '15px'}}
                                        // subTitle={"sadf"}
                                        items={[..._montagetechnieken
                                        ]}
                                    />

                                </ContainerContent>
                                <ContainerContent className={'container-image'}>
                                    <img src={`${window.publicURL}/images/portfolio/layout/pages/assembly/IMG-20200924-WA0015 (Medium).jpg`}></img>
                                </ContainerContent>
                            </Container>
                        </ContainerWrapper>

                            {/* <div ><p style={{textAlign: 'center'}}>Neem contact met ons op</p></div> */}

                        <ContainerWrapper className={'item'}>
                            {/* style={{textAlign: 'center'}} */}
                            {/* <h1  className="container-title">Overige disciplines:</h1>  */}
                            <h1  className="container-title">Overige dienstverlening:</h1> 
                            <Container className='container-content-list'>
                                <ContainerContent>
                                    {/* <ul style={{padding: '15px'}}>
                                        {_disciplines}
                                    </ul> */}
                                    <List style={{padding: '15px'}}
                                        // subTitle={"sadf"}
                                        items={[
                                            ..._disciplines
                                        ]}
                                    />
                                </ContainerContent>
                                <ContainerContent className={'container-image'}>
                                    <img src={`${window.publicURL}/images/portfolio/layout/filling/meetkamer-01E (Medium).jpg`}></img>
                                </ContainerContent>
                            </Container>
                        </ContainerWrapper>
                    </ContainerGroup>
                    
					{/* <Gallery path={process.env.PUBLIC_URL}/> */}
                    <hr/>

                    {/* <CardsHorizontal title={"Een selectie van onze projecten"} _key={1123} projects={entities.companies.SchreuderMetaalEnTechniek.GetProjects()} cards={undefined} /> */}
                
                
                </div>
            </div>
        </>
    );
};

function AssemblyStory() {
    return (<>
    
    </>)
}