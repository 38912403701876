import React from 'react'
import CardItem from './cards/CardItem';
import CardsCarousel from './cards/CardsCarousel';
import { Shuffle } from 'components/tools/arrays';

// Recognition Cards
// const _style = {paddingTop: '2%', animationName: "from-left, from-transparent", animationDuration: "0.75s"};

function PartnerCard(props) {
    
    const _style = {background: `url(${window.publicURL}/logo/partners/${props.image}.png) no-repeat 6px center`}; //{maxHeight: '100px', maxWidth: '300px'}; //{paddingTop: '2%', animationName: "from-left, from-transparent", animationDuration: "0.75s"};


    return <>
        <a target='_blank' href={`${props.url}`}>
            {props.image ? 
            <div className='slide'>
                <img className='slide-img' title={`${props.url}`} //className='logo'
                    // className={`cards_item_img cards_item_img_background ${_type}`}
                    alt={`Image`}
                    
                    src={`${window.publicURL}/logo/partners/${props.image}.png`}
                    style={_style}
                />
            </div> : 
            <div className='slide' style={{width: "300px", padding: "20px 50px"}}>
            <h3 style={{width: "100%"}}>{props.title}</h3>
            </div>
            }
        </a>
    </>
}

export default function PartnerCards(props) {    

    const _cards = [];
    _cards.push(
         <PartnerCard image='alliedmotion' url='https://www.alliedmotion.nl/nl/'/>
        ,<PartnerCard image='bens' url='https://www.onbeperktinbeweging.nl/'/>
        ,<PartnerCard image='blom' url='https://blomopmeer.nl/'/>
        ,<PartnerCard image='bnk' url='https://bnkmechatronica.nl/'/>
        ,<PartnerCard image='braedius' url='https://braedius-medical.com/'/>
        ,<PartnerCard image='brovo' url='https://brovo-scheepsbouw.nl/'/>
        ,<PartnerCard image='demecuur' url='https://www.demercuur.eu/'/>
        ,<PartnerCard image='dormac' url='https://www.dormaccncsolutions.nl/'/>
        ,<PartnerCard image='habbeke' url='https://www.habbeke.nl/'/>
        ,<PartnerCard image='joz' url='https://joz.nl/'/>
        ,<PartnerCard image='marius' url='https://mariusengineering.com/'/>
        ,<PartnerCard image='newdealseals' url='https://newdealseals.com/'/>
        ,<PartnerCard image='nijdra' url='https://www.nijdra.eu/'/>
        ,<PartnerCard image='reijgersenschallies' url='https://www.forceren.net/'/>
        ,<PartnerCard image='rodin' url='https://rodinmachining.nl/'/>
        ,<PartnerCard image='rolan' url='https://www.rolan-robotics.nl/'/>
        ,<PartnerCard image='rrmec' url='https://rrmechatronics.com/'/>
        ,<PartnerCard image='seed' url='https://www.seedprocessing.com/nl/'/>
        ,<PartnerCard image='spruit' url='https://spruit.nl/'/>
        ,<PartnerCard image='tno' url='https://www.tno.nl/en/'/>
        ,<PartnerCard image='tojato' url='https://tojato.com/'/>
        ,<PartnerCard image='ventis' url='https://www.ventis.nl/'/>
        ,<PartnerCard image='vlaming' url='https://www.vlaming-groep.nl/'/>

        ,<PartnerCard image='mkg' url='https://www.mkg.eu/'/>
        ,<PartnerCard image='drenth' url='https://www.drenth-fm.nl/nl/home/'/>
        ,<PartnerCard image='tgs' url='https://www.facebook.com/TechnicalGreenhouseSupport/'/>
        
        ,<PartnerCard title='De Greeuw Engineering' url='http://www.degreeuwengineering.nl/'/>
    );

    Shuffle(_cards);

    const _partners = <>
        <br />
        <hr />
                            
        <div className='width100'>
            {/* <h1 color='red'>Een selectie van onze partners</h1> */}
            <h1 className='text-center' color='red'>In trotse samenwerking met:</h1>

            <CardsCarousel _key={1123} cards={_cards} />
        </div>
        <hr />
    </>;

    return <>
        {_partners}
    </>

}