import { useState } from "react";
import axios from "../../../node_modules/axios/index";
import * as XLSX from '../../../node_modules/xlsx/xlsx';
import { Container, ContainerContent, ContainerWrapper } from "components/layout/containers/Containers";

export function GetExcelFile(_fileName, _sheetIndex = 0) {
  const [excelData, setExcelData] = useState(null);

  HandleFetchFromUrl(_fileName, _sheetIndex).then(
    function(value) {if (excelData == null) setExcelData(value)},
    function(error) {console.log(error);}
  )

  return excelData
}

export async function HandleFetchFromUrl(_url, _sheetIndex = 0) {
    let _return = null;
    try {
      const url = `${_url}`;
        
      const response = await axios.get(url, { responseType: 'arraybuffer' });
      const data = new Uint8Array(response.data);

      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[_sheetIndex];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      _return = jsonData;
    } catch (error) {

      console.error('Error fetching or parsing Excel file:', error);
      _return = null;
    }

    return _return;
};

export function TabelRow(props) {
  const [active, setActive] = useState(false);

  // Row Data
  const rowIndex = props.rowIndex;
  let rowObject = props.rowObject;
  let return_rowObject;

  // Column Data
  let _columnItemIndex = 0, _columnIndex = 0;
  let _columnObject = <></>;
  let _columnInfoObject = <></>;
  const _skipColumn = props._columsToSkip;//[1,2]

  // Styles
  const _style_columnHeader = `uppercase py-2 inline-block break-words`;
  const _style_columnItem = `text-black relative font-normal inline-block height100 [&:nth-child(1n+2)]:border-l-4 border-gray-200 border-solid border-0 align-text-bottom max-sm:px-1 px-2 py-1.5 hyphens-auto break-words`;
  const _style_row = `bg-white overflow-hidden max-sm:text-[0.7rem] odd:bg-gray-100 material-list grid gap-4 justify-center items-center`;
  const _style_rowHeader = `bg-red-700 text-white`;


  if (rowIndex != 0) {
    _columnItemIndex = 0;
    _columnIndex = 0;
    _columnObject = <></>;
    _columnInfoObject = <></>;

    let _active = active;
    let _infoIcon = <>
      <div className={`${_style_columnItem} py-0 max-sm:text-[1.5rem] text-3xl text-green clickable`} onClick={() => {setActive(!active)}}>
        {_active ? bcpChevron() : <i className="fa-solid fa-circle-info logo"></i>}
      </div>
    </>;


    // Loop through the columns [HORIZONTAL]
    rowObject.forEach(columnItem => {

        // Check if the column needs to be skipped
        if (_skipColumn.length > 0 && _columnIndex == _skipColumn.find((i) => i == _columnIndex)) {
            // For now dont do anything

            // if (_columnIndex == 2) {
              _columnInfoObject = <>{_columnInfoObject}
                <div className={`${_style_columnItem}`}>{columnItem}</div>
              </>;
            // }
        } else {

          // Check if it is the header row and the column needs to be styled different
          if (rowIndex == 0) {
            // Header
            // _column = <>{_column}<th className={`${_style_columnHeader} `}>{columnItem}</th></>;
          } else {
            // Item
            _columnObject = <>{_columnObject}<div className={`${_style_columnItem}`}>{columnItem}</div></>;
          }

          _columnItemIndex++;
          // Go to the next column
        }

        // Go to the next row
        _columnIndex++;
    });

    //* Last Row [Information Button]
    if (rowIndex != 0 && _columnItemIndex == _skipColumn.length) {
      _columnObject = <>{_columnObject}
        {_infoIcon}
      </>;
      _columnItemIndex++;
    }

    const _a = `grid-cols-[repeat(2,1fr),.1fr]`;

    // Add the new row to the list
    return_rowObject = <>
      <div className={`${_style_row} ${rowIndex == 0 ? _style_rowHeader : ''} ${_a} `}>{_columnObject}</div>
      {/* Information Row */}
      {_active ? <>
        <div className={`${_style_row} tabel-info-row ${rowIndex == 0 ? _style_rowHeader : ''} ${_a}`}>{_columnInfoObject}</div>
      </>
      : ''}
    </>;
    // rows.push(rowObject);
    return <>{return_rowObject}</>;
  }
}


function bcpChevron(active) {

  {/* Chevron */}
  return <>{<>
      <i className="fa-solid fa-circle-info logo opacity-0"></i>
    <div className='absolute top-0 bottom-0 right-0 left-0 p-1'>
    <div>
      {/* <i className={`fas fa-chevron-${_active ? 'up' : 'down'} text-green-600`}></i> */}
      {/* ${active ? 'rotate-0' : 'hover:rotate-0'} */}
      <i className={`fas fa-chevron-up transition text-green-600 translate-y-1 hover:-translate-y-0.5 text-center duration-150 ease-in-out w-full logo`}></i>
    </div>
  </div>
  </>}</>;
}

export function InfoTabel(object, _columsToSkip = []) {
  // Row Data
  let rows = [];
  let rowIndex = 0;

  if (object != null) {
    
    // Loop through the rows [VERTICAL]
    object.forEach(_row => {

        if (rowIndex != 0) {
          rows.push(<>
            <TabelRow rowObject={_row} _columsToSkip={_columsToSkip}/>
          </>);
        }
        
        rowIndex++;
    });
  } else {
    console.log("Object is null");
  }

  // return <div className='tabel-auto rounded-lg'>{rows}</div>;
  return <>
    <ContainerWrapper>
        <Container>
            <ContainerContent noPadding>
            
            <div className='tabel-auto rounded-lg'>{rows}</div>
            
            </ContainerContent>
        </Container>
    </ContainerWrapper>
  </>;
}