import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import "./NavbarFoldMenu.css";
import parse from 'html-react-parser';

import NavbarButtons from "./NavbarButtons";

var iconPhone = '<i className="fas fa-phone-alt"></i>';
var iconMail = '<i className="fas fa-envelope"></i>';

export default function Navbar() {
  const [click, setClick] = useState(false); // Input variable
  const [showMobileMenuButton, setMobileMenuButton] = useState(true); // Button variable
  const [first, setFirst] = useState(true);

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  // Set the variable to the opposite of what it is currently
  const handleClick = () => {
    setClick(!click);

    // window.scrollTo({
    //   top: 0,
    //   behavior: 'smooth'
    // });
  }

  const forceRerender = () => {
    forceUpdate();
  }

  const closeMobileMenu = () => {

    setClick(false);
  }
  const closeFoldMenu = () => setClick(false);

  // Dont show the button on Mobile
  const showButton = () => {
    if (window.innerWidth <= 960) {
      if (!showMobileMenuButton) setMobileMenuButton(true);
      // setClick(false);// This does not work on mobile... When you scroll through the menu it will be triggered
    } else {
      if (showMobileMenuButton) setMobileMenuButton(false);
      setClick(false);
    }
  };

  if (first) {
    showButton();
    setFirst(false);
  }

  window.addEventListener("resize", showButton);

	// When the user scrolls down 80px from the top of the document, resize the navbar's padding and the logo's font size
	// window.onscroll = function() {scrollFunction()};

	// function scrollFunction() {
	// 	if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
	// 		document.getElementsByClassName("navbar")[0].style.height = "80px";
	// 		document.getElementsByClassName("navbar-logo")[0].style.width = "200px";
	// 	} else {
	// 		document.getElementsByClassName("navbar")[0].style.height = "100px";
	// 		document.getElementsByClassName("navbar-logo")[0].style.width = "300px";
	// 	}
	// }
  	const _phoneNumber = global.config.companyInfo.phoneNumberMain;
  	const _eMail = global.config.companyInfo.emailMain;

    const _between = window.isPhoneSize ? <br/> : <>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</>;

  return (
    <>
      <div className="navbar-wrapper">

        {/* HERE */}
        <div className="navbar-title bgr"><h3>{parse(iconPhone)} <a className="interactive-info font-light" href={`tel:${_phoneNumber}`}>{_phoneNumber}</a> {_between} {parse(iconMail)} <a className="interactive-info font-light" href={`mailto:${_eMail}`}>{_eMail}</a></h3></div>

        
        <nav className="navbar">
          
          <div className="navbar-container">
            {/* Logo in the Navbar */}
            <NavbarLogo 
            closeMobileMenu={closeMobileMenu}
            closeFoldMenu={closeFoldMenu}
            />

            {/* When a pages loads close the menu with "closeMobileMenu" */}
            <Link to="/" className="navbar-close-menu" onClick={closeMobileMenu}>
              {/* Schreuder Customs */}
            </Link>
          </div>

          <NavbarButtons
            handleClick={handleClick}
            click={click}
            closeMobileMenu={closeMobileMenu}
          />

          {/* {button && <Button buttonStyle='btn--outline'>SIGN UP</Button>} */}
          {/* </div> */}
        </nav>
      </div>
    </>
  );
}

export function NavbarLogo(props) {

  function _a() {
    
  }
  
  return <div className={`navbar-logo-container`} style={props.style}>
    {/* Logo in the Navbar */}
    <a href="/" title="Home">
      <img
        className="logo navbar-logo"
        src={window.publicURL + global.config.companyMedia.logo}
        width="200px"
      ></img>
    </a>

    {/* When a pages loads close the menu with "closeMobileMenu" */}
    <Link to="/" className="navbar-close-menu" onClick={() => {props.closeMobileMenu(); props.closeFoldMenu()}}>
      {/* Example Company */}
    </Link>
  </div>
}