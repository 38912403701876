import PartnerCards from 'components/layout/PartnerCards';
import CardItem from 'components/layout/cards/CardItem';
import Cards from 'components/layout/cards/Cards';
import CardsCarousel from 'components/layout/cards/CardsCarousel';
import CardsHorizontal from 'components/layout/cards/CardsHorizontal';
import CardsVertical from 'components/layout/cards/CardsVertical';
import { Container, ContainerContent, ContainerGroup, ContainerWrapper } from 'components/layout/containers/Containers';
import HeaderSection from 'components/layout/pages/home/HeaderSection';
import CompanyStory from 'components/layout/text/CompanyStory';
import { importAllArray } from 'images';
import React from 'react';
import { P, Title } from 'styling/Text';


// window.publicURL + '/images/portfolio/layout/pages/contact/meetkamer-01E (Medium).jpg'
export default function About() {
    const _imageArray = importAllArray(
		require.context('../../../public/images/portfolio/layout/pages/home', true, /^(?!.*\/not-used\/).*\/.*.\.(JPG|png|jpe?g|svg)$/)
        ,'images/portfolio/layout/pages/home'
    );

    return (
        <>
            <div className="page">
                <HeaderSection title={"Over Ons"} mediaHeight="40vh" imageArray={_imageArray} showInfo={false} />
                <div className='page-content'>

                    <CompanyStory line={false}>
                        <br/>
                        <P>
                            Wat begon als een éénmanszaak in 2007, is uitgegroeid tot een sterk en ambitieus team van jonge vaklieden dat vandaag de dag Schreuder Metaal en Techniek vormt. Bij ons staan kwaliteit én klanttevredenheid voorop.
                        </P>
                        <P>
                            Het team van Schreuder Metaal en Techniek blijft voortdurend vooruitkijken naar de nieuwste innovaties om u en uw branche optimaal te ondersteunen. Onze expertise wordt versterkt door de nieuwste gereedschappen en veelzijdige bewerkingsmachines.
                        </P>
                    </CompanyStory>
                    
                    <ContainerWrapper style={{animationName: "from-bottom, from-transparent", animationDuration: "1.4s"}}>
                        <Container background={false}>
                            <ContainerContent>
                                    
                                <div style={{ paddingLeft: "2%" }}>
                                    

                                    <ContainerGroup gap={{columnGap: "5%"}}>
                                        <ContainerWrapper className={'item'}>
                                            <h1 className="container-title">Missie:</h1>
                                            <Container>
                                                <ContainerContent>
                                                <P>
                                                    We zetten onze kwaliteitsnormen en servicegerichtheid hoog op onze agenda. Door de jaren heen hebben we dit zorgvuldig opgebouwd en streven we ernaar om dit continu te verbeteren en te behouden.
                                                </P>

                                                <P>
                                                    Naast onze hoge kwaliteitszorg hechten wij veel waarde aan toegankelijke communicatie en bereikbaarheid. Wij waarborgen onze leverbetrouwbaarheid door middel van een strak georganiseerde planning.
                                                </P>

                                                <P>
                                                    Dankzij ons bevlogen team schakelen wij snel om en hebben wij een stabiel platform gecreëerd, wat ons in staat stelt om samen met onze klanten mee te denken over zowel engineering als de slimste oplossingen voor het realiseren van hun product of project.
                                                </P>
                                                </ContainerContent>
                                            </Container>
                                        </ContainerWrapper>

                                        <ContainerWrapper className={'item'}>
                                            <h1  className="container-title">Visie:</h1> 
                                            <Container>
                                                <ContainerContent>
                                                    <P>
                                                        Wij streven naar een snelle en efficiënte reactie op offerteaanvragen en het leveren van maatwerk dat volledig aansluit bij de specifieke eisen van elk project. Wij passen de nieuwste verspaningstechnieken toe om onze klanten altijd te voorzien van de beste mogelijkheden en resultaten. Dankzij ons uitgebreide netwerk blijven we continu op de hoogte van de nieuwste ontwikkelingen op het gebied van materialen en gereedschappen, zodat we onze klanten nog beter kunnen adviseren over de meest geschikte toepassingen voor hun product of project.
                                                    </P>

                                                    <P>
                                                        Daarnaast zetten we ons in voor de voortdurende uitbreiding van ons netwerk en het onderhouden van nauwe samenwerkingen met zowel klanten als leveranciers. Wij denken actief met onze klanten mee op het gebied van maakbaarheid, ontwerp en engineering, om samen de beste oplossingen te realiseren en een blijvende meerwaarde te creëren.
                                                    </P>

                                                </ContainerContent>
                                            </Container>
                                        </ContainerWrapper>
                                    </ContainerGroup>
                                    

                                    </div>
                            </ContainerContent>
                        </Container>
                    </ContainerWrapper>
                                    
                                    {/* <ContainerWrapper>
                                        <Container borderLeft={true}>
                                            <ContainerContent>
                                            
                                            <h2 className='text-center'>"Door doorgaande feedback van onze klanten kunnen wij ons werk als topkwaliteit benoemen!"</h2>
                                            
                                            </ContainerContent>
                                        </Container>
                                    </ContainerWrapper> */}

                    <ContainerWrapper style={{animationName: "from-bottom, from-transparent", animationDuration: "1.4s"}}>
                        <Container background={false}>
                            <ContainerContent>
                                    
                            <div style={{ paddingLeft: "2%" }}>

                            {/* Partners */}
                            <PartnerCards />
                                    
                            <br/>

                            <h1 color='red'>Ontstaan van het familiebedrijf:</h1>
                            <br/>

                            <P>
                                Dennis Schreuder is zijn carrière in de metaal en techniek begonnen bij diverse bedrijven.
                                In 2007 heeft Dennis de stap gezet om zelfstandig verder te gaan als éénmanszaak.
                                In eerste instantie vanaf het thuisadres op de Tomatenmarkt in Zwaagdijk, Schreuder Metaal en Techniek was “geboren”.
                                Door de groei van zijn bedrijf ontstond er in 2014 de mogelijkheid om personeel aan te nemen, het machinepark uit te breiden
                                en als werkgever verder te gaan.
                            </P>
                            <P>
                                De investering in personeel en machines heeft er echter toe geleid dat de ruimte al snel te klein werd.
                                In 2016 is er dan ook voor gekozen om de intrek te nemen in het huidige pand op de Perenmarkt in Zwaagdijk.
                                Vanaf die tijd hebben de werkzaamheden zich uitgebreid naar klanten in diverse bedrijfstakken zoals de Agrisector, voedingsindustrie,
                                medische branche, verfindustrie, jachtbouw, bloemen- en zadensector, prototyping, kunststofverwerkende industrie en machinebouw.
                                En daarnaast, om ons vak verder te promoten, bieden we studenten stageplaatsen aan en dienen wij als erkend leerbedrijf.
                            </P>

                            
                        </div>
                            </ContainerContent>
                        </Container>
                    </ContainerWrapper>

                    
                </div>

            </div>
        </>
    );
};

const BCP = <div style={{ display: "flex", flexDirection: "row" }}>
{/* <img width="100%" src='images\image-dennis-01.jpg'></img> */}
<div style={{ paddingLeft: "2%" }}>
    <p>
        Dennis Schreuder is zijn carrière in de metaal en techniek begonnen bij diverse bedrijven.
        In 2007 heeft Dennis de stap gezet om zelfstandig verder te gaan als éénmanszaak.
        In eerste instantie vanaf het thuisadres op de Tomatenmarkt in Zwaagdijk, Schreuder Metaal en Techniek was “geboren”.
        Door de groei van zijn bedrijf ontstond er in 2014 de mogelijkheid om personeel aan te nemen, het machinepark uit te breiden
        en als werkgever verder te gaan.
    </p>
    <br></br>
    <p>
        De investering in personeel en machines heeft er echter toe geleid dat de ruimte al snel te klein werd.
        In 2016 is er dan ook voor gekozen om de intrek te nemen in het huidige pand op de Perenmarkt in Zwaagdijk.
        Vanaf die tijd hebben de werkzaamheden zich uitgebreid naar klanten in diverse bedrijfstakken zoals de Agrisector, voedingsindustrie,
        medische branche, verfindustrie, jachtbouw, bloemen- en zadensector, prototyping, kunststofverwerkende industrie en machinebouw.
        En daarnaast, om ons vak verder te promoten, bieden we studenten stageplaatsen aan en dienen wij als erkend leerbedrijf.
    </p>
</div>
</div>;
