import React from 'react';
import HeaderSection from '../layout/pages/home/HeaderSection';
import CompanyStory from '../layout/text/CompanyStory';
import Cards from '../layout/cards/Cards';
import CardsHorizontal from '../layout/cards/CardsHorizontal';
import { importAll, importAllArray } from 'images';

import {entities} from 'entities.js';
import Gallery from 'components/layout/media/Gallery';
import { Container, ContainerContent, ContainerWrapper, ContainerGroup } from 'components/layout/containers/Containers';
import { ArrayToHTMLList } from 'components/tools/arrays';
import { P } from 'styling/Text';
import { List } from 'components/layout/text/TextComponents';


const story = undefined;
const title = `Fabricage`;

export default function Manufacturing () {
	let _video = `${process.env.PUBLIC_URL}/videos/video-1.mp4`;
	let _image = `${process.env.PUBLIC_URL}/images/portfolio/layout/pages/home/20210526_121644.jpg`;

	const _imageArray = importAllArray(
		require.context('../../../public/images/portfolio/layout/pages/assembly', true, /^(?!.*\/not-used\/).*\/.*.\.(JPG|png|jpe?g|svg)$/)
        ,'images/portfolio/layout/pages/assembly'
	);

    let _prototyping = [
         'Te adviseren in ontwerp en maakbaarheid'
        ,'Te ondersteunen met Tekenwerk en Engineering'
        ,'Mee te denken of te adviseren over materiaalkeuze of toepassing'
        ,'Met een uitgebreid assortiment en kennis van Gereedschappen in staat zijn complexe bewerkingen uit te voeren'
        ,'Compleet ontzorgen door ook eventuele nabehandelingen te verzorgen'
        ,'-	Mee te denken en aanpassingen te doen aan ontwerpen voor het optimaliseren van de bewerkingsprocessen t.b.v. een sneller en efficiënter productieproces dat kan leiden tot een kostenbesparende prijs'
    ];

    let _tekenwerk = [
        "Maakbaarheid	(goed te (re)produceren)"
        ,"Materiaalkeuze	(voor de duurzaamheid van het product)"
        ,"Integratie	(Is het ontwerp duurzaam en makkelijk te integreren in bestaande en toekomstige projecten)"
        ,"Processen	(Vastleggen van fabricageprocessen op tekening en in tekst)"
        ,"Advies	(Productie adviezen (naast Verspaning) na afronden Protofase en ondersteuning in deze keuze)"

    ];

    return (
        <>
            <div className="page">
                <HeaderSection title={`${title}`} mediaHeight={'450px'} imageArray={_imageArray} showInfo={false} logo={false} />

                <div className="page-content">
                    <CompanyStory title={`Prototyping`}>
                        <P>
                            Onze basis ligt bij de vervaardiging van Prototypes (enkelstuks) en is vanaf het begin onze ‘Kernactiviteit’.
                        </P>
                        <P>
                            Door onze jarenlange ervaring en uitgebreide assortiment aan Materialen, Gereedschappen en een breed netwerk zijn wij in staat om:
                            
                            <div style={{padding: '10px'}}>
                                <List style={{paddingLeft: '10px'}}
                                    // subTitle={"sadf"}
                                    items={[..._prototyping
                                    ]}
                                />
                            </div>
                        </P>

                        <P>
                            Wij zijn ervan overtuigd dat wij een allesomvattende oplossing kunnen bieden en ervoor kunnen zorgen dat het ontwikkelingsproces verkort kan worden en sneller een concreet ‘definitief’ ontwerp op tafel komt te liggen.
                        </P>

                        
                        
                    </CompanyStory>

                    <hr/>

                    <CompanyStory title={`Serieproductie`}>
                        <P>
                            Naast het ontwikkelingsproces kunnen wij ook de serieproductie realiseren. Met onze flexibel inzetbare bewerkingscellen kunnen wij heel goed series van 10 tot 5000 stuks aan en in veel gevallen kunnen we onze cellen aanpassen voor grotere aantallen, door middel van het maken van speciale opspanmallen of opspangereedschappen die wij in eigen beheer kunnen ontwerpen en uitvoeren. (op aanvraag)
                        </P>
                        <P>
                            Door de ervaring met het maken van de prototypes kunnen wij snel en effectief de procesoptimalisatie voor het seriewerk doorvoeren, zodat er een efficiënt en economisch eindresultaat uitrolt.
                        </P>
                        
                    </CompanyStory>

                    <hr/>
                    
                    <CompanyStory title={`Tekenwerk en Engineering`}>
                        <P>
                            Wij ontzorgen de klant graag op brede schaal en daarom hebben we onze diensten sinds een aantal jaren uitgebreid met Tekenwerk en Engineering.
                        </P>
                        <P>
                            Dit houdt in dat we vanaf het beginstadium al kunnen meedenken over het ontwerp en mechanische eigenschappen waar het product aan moet voldoen, om tot een hoogwaardig en betaalbaar concept te komen lopen we de volgende punten met u af:
                            <div style={{padding: '10px'}}>
                                <List style={{paddingLeft: '10px'}}
                                    // subTitle={"sadf"}
                                    items={[..._tekenwerk
                                    ]}
                                />
                            </div>
                        </P>
                        <P>
                            U kunt rekenen op de vakkundige begeleiding van onze projectleiders en de brede kennis van Dennis (eigenaar)
                        </P>
                        
                    </CompanyStory>

                    
                    
					{/* <Gallery path={process.env.PUBLIC_URL}/> */}
                    <hr/>

                    {/* <CardsHorizontal title={"Een selectie van onze projecten"} _key={1123} projects={entities.companies.SchreuderMetaalEnTechniek.GetProjects()} cards={undefined} /> */}
                
                
                </div>
            </div>
        </>
    );
};

function AssemblyStory() {
    return (<>
    
    </>)
}

{/* <div style={{padding: '10px'}}>
    <List style={{padding: '1px'}}
        // subTitle={"sadf"}
        items={[..._montagetechnieken
        ]}
    />
</div> */}

{/* <>
<ContainerGroup style={{alignItems: 'stretch', marginTop: '0px'}}>
    <ContainerWrapper className={'item'}>
        <Container className='container-content-list'>
            <ContainerContent style={{paddingLeft: '15px'}}>
                <List style={{padding: '1px'}}
                    // subTitle={"sadf"}
                    items={[..._montagetechnieken
                    ]}
                />

            </ContainerContent>
            <ContainerContent className={'container-image'}>
                <img src={`${window.publicURL}/images/portfolio/layout/pages/assembly/IMG-20200924-WA0015 (Medium).jpg`}></img>
            </ContainerContent>
        </Container>
    </ContainerWrapper>

</ContainerGroup>
</> */}