import CardItem from 'components/layout/cards/CardItem';
import Cards from 'components/layout/cards/Cards';
import CardsCarousel from 'components/layout/cards/CardsCarousel';
import CardsHorizontal from 'components/layout/cards/CardsHorizontal';
import CardsVertical from 'components/layout/cards/CardsVertical';
import { Container, ContainerContent, ContainerGroup, ContainerWrapper } from 'components/layout/containers/Containers';
import HeaderSection from 'components/layout/pages/home/HeaderSection';
import CompanyStory from 'components/layout/text/CompanyStory';
import { List, SpaceBig } from 'components/layout/text/TextComponents';
import { importAllArray } from 'images';
import React from 'react';
import { P, Title, TitleText, TitleTextBig } from 'styling/Text';


const _showButtons = false;

// Recognition Cards
const _style = {paddingTop: '2%', animationName: "from-left, from-transparent", animationDuration: "0.75s"};
// Normale vacatures
const _vacatures = [];
_vacatures.push(
    <Vacature filled={false} type="CNC Frezer" requirements={[
         <>Ervaren Frezer (minimaal 5 tot 10 jaar)</>
        ,<>Ervaring met <b>Freesbank</b> met <b>5-assen</b></>
        ,<>Ervaring met <b>EZCAM-software</b> (EZ-Mill)</>
        ,<>Ervaring met <b>Heidenhain</b> en <b>Fagor</b> besturing</>
        ,<>Flexibele werkhouding (staat open voor verschillende werkzaamheden)</>
    ]}/>
);
_vacatures.push(
    <Vacature filled={false} type="CNC Draaier" requirements={[
         <>Ervaren Draaier (minimaal 5 tot 10 jaar)</>
        ,<>Ervaring met <b>Draaibank</b> met <b>5 assen</b> (Y-as + overnamespil)</>
        ,<>Ervaring met aangedreven gereedschappen</>
        ,<>Ervaring met <b>EZCAM-software</b> (EZ-Turn)</>
        ,<>Ervaring met <b>Siemens</b> en <b>Fagor besturing</b></>
        ,<>Flexibele werkhouding (staat open voor verschillende werkzaamheden)</>
    ]}/>
);

let _vacaturesAmount = 0;
_vacatures.forEach(element => {
    if (element.props.filled == false) _vacaturesAmount++;
});

// Stage plekken
const _stage = [];
_stage.push(
    <Vacature filled={true} type="Snuffelstage (1 dag per week) Periodiek" requirements={<>
    <P>Ben jij bezig of begonnen aan een technische of specialistische opleiding en zoek je een plekje om in
de praktijk te proeven hoe het er aan toe gaat en zo wat ervaring op te doen.</P>

        <P>Neem dan in samenspraak met je schoolbegeleiding contact met ons op om te kijken of we iets voor
elkaar kunnen betekenen.</P>
    </>} subtitle="" />
);
_stage.push(
    <Vacature filled={true} type="Stage (4 dagen per week) tot eindexamen" requirements={<>
        <P>Zoek jij een plek voor het afronden van jouw opleiding in de Verspaning met de nodige begeleiding
en mogelijkheden op verschillende disciplines.</P>
    
            <P>Neem dan in samenspraak met je Stagebegeleider/Mentor contact met ons op om te kijken of we
iets voor elkaar kunnen betekenen en of er in de gewenste periode plaats is.</P>
        </>} subtitle=""/>
);

let _stageAmount = 0;
_stage.forEach(element => {
    if (element.props.filled == false) _stageAmount++;
});

// Update vacature amount
const _vacaturesAmountTotal = _vacaturesAmount + _stageAmount;
window.vacatureAmount = _vacaturesAmountTotal > 0 ? _vacaturesAmountTotal : undefined;

// --- Requirement list example
// _vacatures.push(
//     <Vacature type="" requirements={[
//         "Werken in een jong en dynamisch bedrijf"
//         ,"Doorgroeimogelijkheden"
//         ,"Werken in een professioneel team"
//         ,"Zeer afwisselende projecten"
//         ,"Werken met verschillende materialen"
//         ,"2x per jaar een bedrijfsuitje met aandacht voor teambuilding"
//     ]} subtitle="Wat bieden wij"/>
// );

export function ButtonSmall(props) {
    return (_showButtons ? <>
            <div style={{display: "inline"}}>
                <a style={{textDecoration: "none"}} href={props.href} target="_blank">
                    <div style={{display: "inline", width: '100%'}} className='button-small background-blue'>
                        {props.title}
                    </div>
                </a>
            </div>
            </> : "")
}

function Vacature(props) {
    let _button = props.dontShowButton ? "" : <ButtonSmall title={"Solliciteer"} />
    let _subTitle = props.subtitle ? props.subtitle : (props.subtitle == "" ? "" : "Vereisten:");


    return <>
        <hr/>
        
        <h1 className={`container-title ${props.color ? props.color : ""}`} style={{ marginBottom: "0" }}>{props.type} {props.filled ? <a className='text-accent'> | Gevuld</a> : <span>{_button}</span>}</h1>
        
        {props.message ? <>
            <ContainerWrapper className={'item'} width="100%">
                <Container background={false} style={{overflow: "visible"}}>
                    <ContainerContent style={{padding: "0"}}>
                        <P noBreak={true}>Heb je al ervaring als CNC Frezer en ben je op zoek naar een nieuwe uitdaging? Reageer dan op deze vacature, wie weet werk jij dan straks in een enthousiast, ambitieus en gezellig team!</P>
                    </ContainerContent>
                </Container>
            </ContainerWrapper>
        </> : ""}

        <ContainerGroup background={false} gap={{ columnGap: "5%" }} style={{marginTop: "0%"}}>
            <ContainerWrapper className={'item'} width={props.subtitle == "" ? "0%" : "17%"}>
                <Container background={false} style={{overflow: "visible"}}>
                    <ContainerContent>
                        <h3 className='text-black'>{_subTitle}</h3>
                    </ContainerContent>
                </Container>
            </ContainerWrapper>

            <ContainerWrapper className={'item'} style={{marginTop: "0%"}}>
                <h1  className="container-title"></h1> 
                <Container background={false}>
                    <ContainerContent style={{padding: "0 2%"}}>
                        {Array.isArray(props.requirements) ? <>
                        <List lineHeight="1.85" itemClass="text-black2" bulletPoints={true}
                            // subTitle={"sadf"}
                            items={[...props.requirements
                            ]}
                        />
                        </> : props.requirements}
                    </ContainerContent>
                </Container>
            </ContainerWrapper>
        </ContainerGroup>
        <br />
    </>
}

function ShowVacatures(props) {
    return <>
        <TitleTextBig>{props.title}</TitleTextBig>
        {/* <hr/> */}
        {props.vacatures}
    </>
}



// window.publicURL + '/images/portfolio/layout/pages/contact/meetkamer-01E (Medium).jpg'
export default function About() {
    const _imageArray = importAllArray(
		require.context('../../../public/images/portfolio/layout/pages/home', true, /^(?!.*\/not-used\/).*\/.*.\.(JPG|png|jpe?g|svg)$/)
        ,'images/portfolio/layout/pages/home'
    );

    return (
        <>
            <div className="page">
                <HeaderSection title={`Vacatures`} mediaHeight="40vh" imageArray={_imageArray} showInfo={false} />
                <div className='page-content'>

                <ContainerWrapper>
                    <Container background={false}>
                        <ContainerContent noPadding={true}>
                        
                        {ShowVacancyAmount()}
                        
                        </ContainerContent>
                    </Container>
                </ContainerWrapper>
                    
                <ContainerWrapper className="from-bottom-play">
                    <Container >
                        <ContainerContent>
                        
                        <img style={{height: "120px", float: 'right'}} src={window.publicURL + '/images/misc/erkend-leerbedrijf.png'}></img>
                        
                        <P>
                        Wij zijn een snel groeiend jong bedrijf met een uniek klantenbestand.
                        Wij begeven ons op zeer uiteenlopende vakgebieden, waardoor wij veel ervaring hebben opgedaan op veel vele
                        verschillende materialen en eindproducten.
                        </P>
                        
                        <P>
                        Hierdoor kunnen wij met de klanten meedenken en zijn een belangrijke spil in het ontwerp en realiseren van hun
                        producten en kunnen zo eventuele knelpunten e/o lastige bewerkingen voorkomen.
                        </P>
                                
                        
                        </ContainerContent>
                    </Container>
                    </ContainerWrapper>                                    
                    
                    <ContainerWrapper style={{animationName: "from-bottom, from-transparent", animationDuration: "1.4s"}}>
                        <Container background={false}>
                            <ContainerContent>
                                    
                                <div style={{ paddingLeft: "2%" }}>                                    
                                    
                                    <ShowVacatures title={"Productie"} vacatures={_vacatures} />
                                    
                                </div>

                            </ContainerContent>
                        </Container>
                    </ContainerWrapper>


                    <ContainerWrapper>
                        <Container borderLeft={true}>
                            <ContainerContent>
                            
                            {/* <hr/> */}
                            {/* <SpaceBig/> */}
                                <h2 className='text-center'>Wij stellen de persoon graag centraal en bieden daarom de mogelijkheden om met jou continu te werken aan jouw ambities en persoonlijke groei.</h2>
                                <br/>
                                <h2 className='text-center text-black'>Mocht je interesse hebben, kom langs! De koffie staat klaar.</h2>
                                {/* <br /> */}
                                {/* <h2 className='text-center'>Maak niet uit hoe je langs komt, kom langs!</h2> */}
                                
                            {/* <SpaceBig/> */}
                            
                            </ContainerContent>
                        </Container>
                    </ContainerWrapper>

                    <ContainerWrapper style={{animationName: "from-bottom, from-transparent", animationDuration: "1.4s"}}>
                        <Container background={false}>
                            <ContainerContent>
                                    
                                <div style={{ paddingLeft: "2%" }}>

                                    <ShowVacatures title={"Stage"} vacatures={_stage} />
                                    
                                </div>

                            </ContainerContent>
                        </Container>
                    </ContainerWrapper>
                                    
                    
                    <ContainerWrapper style={{animationName: "from-bottom, from-transparent", animationDuration: "1.4s"}}>
                        <Container background={false} >
                            <ContainerContent style={{paddingTop: "0%"}}>

                            <div style={{ paddingLeft: "2%" }}>

                                    <Vacature type="Wat bieden wij:" color="text-blue" requirements={[
                                        <>Werken in een jong en dynamisch bedrijf</>
                                        , <>Doorgroeimogelijkheden</>
                                        , <>Werken in een professioneel team</>
                                        , <>Zeer afwisselende projecten</>
                                        , <>Werken met verschillende materialen</>
                                        , <>2x per jaar een bedrijfsuitje met aandacht voor teambuilding</>
                                    ]} subtitle="" dontShowButton={true}/>            
                            
                                </div>
                            </ContainerContent>
                        </Container>
                    </ContainerWrapper>

                    <ContainerWrapper style={{animationName: "from-bottom, from-transparent", animationDuration: "1.4s"}}>
                        <Container background={false}>
                            <ContainerContent style={{paddingTop: "0%"}}>

                            <div style={{ paddingLeft: "2%" }}>

                                    <TitleText>Over Schreuder Metaal en Techniek:</TitleText>
                                    <P>
                                    De eigenaar, Dennis Schreuder, heeft altijd een familiare sfeer gecreëerd en maakt zelf onderdeel deel uit van de
                                    groep CNC ‘vakidioten’ op de werkvloer. Hierdoor is er onderling direct en goed contact met elkaar en is er altijd
                                    iemand die jouw vragen zal beantwoorden.<br/>
                                    Met het moderne machinepark, een goed georganiseerd en netjes ingedeelde werkplaats, zijn wij ervan overtuigd
                                    dat iedereen zich hier snel thuis zal voelen.
                                    </P>
            
                            
                                </div>
                            </ContainerContent>
                        </Container>
                    </ContainerWrapper>

                    <ContainerWrapper>
                        <Container borderLeft={true}>
                            <ContainerContent>
                            
                            <TitleText>Open sollicitatie</TitleText>
                                    <P>Kunnen we je op dit moment niet de gedroomde functie aanbieden?</P>

                                    <P>Door de snelle groei die wij doormaken de laatste jaren hebben we in de nabije toekomst misschien
                                    wel die functie wat beter aansluit bij jouw profiel.</P>

                                    <P>Mocht je geïnteresseerd zijn in ons en wil je in aanmerking komen een ‘toekomstige’ functie bij ons
                                    in te komen vullen, mail ons met uw motivatie.</P>
                                    {/* in te komen vullen, laat dan wat gegevens achter doormiddel van het invullen van het formulier ‘Open sollicitatie’.</P> */} 
                                    <h1><ButtonSmall title={"Solliciteer"} /></h1>
                            
                            </ContainerContent>
                        </Container>
                    </ContainerWrapper>

                    <SpaceBig/>

                    
                </div>

            </div>
        </>
    );
};





function _number1(props) {
    let _color = ""; //(props.amount > 0 ? 'text-green' : 'color-accent')

    return <>
        <ContainerWrapper className={`item ${props.className}`} style={{ marginTop: "0%" }}>
                <Container background={false} style={{ overflow: "visible", margin: '0' }}>
                    <ContainerContent>
                        <h1 className='text-center'>Open {props.title}: <span className={_color}>{props.amount}</span></h1>
                    </ContainerContent>
                </Container>
            </ContainerWrapper>
    </>
}

function ShowVacancyAmount() {
    return <>
        <ContainerGroup gap={{ columnGap: "0" }}>
            <_number1 className="from-left-play" title="Vacatures" amount={_vacaturesAmount} />
            <_number1 className="from-right-play" title="Stageplekken" amount={_stageAmount} />
        </ContainerGroup>
        <br />
    </>
}

function Test(props) {
    let _color = (props.amount > 0 ? 'background-green' : 'background-accent')

    return <>
        <div style={{ display: "inline"}}>
            <h1 style={{ display: "inline" }} className='text-left'>{props.title}: <span className={`${_color} rounded-pill text-white`}>{props.amount}</span></h1>
            
        </div>
    </>
}

const _number2 = <>
    <h1 className='text-left'>
        {`Vacatures: ${_vacatures.length}`} <span className='text-accent'>|</span> {`Stage plekken: ${_stage.length}`}
    </h1>
    <br/>
    <br/>
    <hr/>
    <br/>
</>

const _number3 = <>
    <Test title={"Vacatures"} amount={1} />
    <h1 style={{ display: "inline" }}> | </h1>
    <Test title={"Stage"} amount={0} />
    <br/>
    <br/>
    <br/>
</>

const end1 = <>
    <br />
    <h1 color='red'>Over Schreuder Metaal en Techniek:</h1>
    <br />
    <P>
        Wij zijn een snel groeiend jong bedrijf met een uniek klantenbestand.
        Wij begeven ons op zeer uiteenlopende vakgebieden, waardoor wij veel ervaring hebben opgedaan op veel vele
        verschillende materialen en eindproducten.
    </P>
    <P>
        Hierdoor kunnen wij met de klanten meedenken en zijn een belangrijke spil in het ontwerp en realiseren van hun
        producten en kunnen zo eventuele knelpunten e/o lastige bewerkingen voorkomen.
    </P>
    <P>
        De eigenaar, Dennis Schreuder, heeft altijd een familiare sfeer gecreëerd en maakt zelf onderdeel deel uit van de
        groep CNC ‘vakidioten’ op de werkvloer. Hierdoor is er onderling direct en goed contact met elkaar en is er altijd
        iemand die jouw vragen zal beantwoorden.<br />
        Met het moderne machinepark, een goed georganiseerd en netjes ingedeelde werkplaats, zijn wij ervan overtuigd
        dat iedereen zich hier snel thuis zal voelen.
    </P>
    <P>
        Wij stellen de persoon graag centraal en bieden daarom de mogelijkheden om met jou continue te werken aan jouw
        ambities en persoonlijke groei.
    </P>
</>;

const _bcpIntro = <>
    <ContainerGroup gap={{ columnGap: "1%" }} style={{ height: '100%' }}>
        <ContainerWrapper className={'item'}>
            {/* <h1 className="container-title">Missie:</h1> */}
            <Container>
                <ContainerContent >
                    <P>
                        Wij zijn een snel groeiend jong bedrijf met een uniek klantenbestand.
                        Wij begeven ons op zeer uiteenlopende vakgebieden, waardoor wij veel ervaring hebben opgedaan op veel vele
                        verschillende materialen en eindproducten.
                    </P>
                                    
                    <P>
                        Hierdoor kunnen wij met de klanten meedenken en zijn een belangrijke spil in het ontwerp en realiseren van hun
                        producten en kunnen zo eventuele knelpunten e/o lastige bewerkingen voorkomen.
                    </P>
                </ContainerContent>
            </Container>
        </ContainerWrapper>

        <ContainerWrapper className={'item'}>
            {/* <h1  className="container-title">Visie:</h1>  */}
            <Container background={true}>
                <ContainerContent>
                    <img style={{ height: "120px", padding: 'auto' }} src={window.publicURL + '/images/misc/erkend-leerbedrijf.png'}></img>

                </ContainerContent>
            </Container>
        </ContainerWrapper>
    </ContainerGroup>
</>;

const BCP = <div style={{ display: "flex", flexDirection: "row" }}>
{/* <img width="100%" src='images\image-dennis-01.jpg'></img> */}
<div style={{ paddingLeft: "2%" }}>
    <p>
        Dennis Schreuder is zijn carrière in de metaal en techniek begonnen bij diverse bedrijven.
        In 2007 heeft Dennis de stap gezet om zelfstandig verder te gaan als éénmanszaak.
        In eerste instantie vanaf het thuisadres op de Tomatenmarkt in Zwaagdijk, Schreuder Metaal en Techniek was “geboren”.
        Door de groei van zijn bedrijf ontstond er in 2014 de mogelijkheid om personeel aan te nemen, het machinepark uit te breiden
        en als werkgever verder te gaan.
    </p>
    <br></br>
    <p>
        De investering in personeel en machines heeft er echter toe geleid dat de ruimte al snel te klein werd.
        In 2016 is er dan ook voor gekozen om de intrek te nemen in het huidige pand op de Perenmarkt in Zwaagdijk.
        Vanaf die tijd hebben de werkzaamheden zich uitgebreid naar klanten in diverse bedrijfstakken zoals de Agrisector, voedingsindustrie,
        medische branche, verfindustrie, jachtbouw, bloemen- en zadensector, prototyping, kunststofverwerkende industrie en machinebouw.
        En daarnaast, om ons vak verder te promoten, bieden we studenten stageplaatsen aan en dienen wij als erkend leerbedrijf.
    </p>
</div>
</div>;