import React, { useEffect, useState, Component } from 'react';
import HeaderSection from '../layout/pages/home/HeaderSection';
import CompanyStory from '../layout/text/CompanyStory';
import { importAll, importAllArray } from 'images';


import { Container, ContainerContent, ContainerWrapper, ContainerGroup } from 'components/layout/containers/Containers';
import { P } from 'styling/Text';
import { GetExcelFile } from 'components/tools/excel';
import { List } from 'components/layout/text/TextComponents';

const story = undefined;
const title = `Materialen`;

export default function Materialen () {

	const _imageArray = importAllArray(
		require.context('../../../public/images/portfolio/layout/pages/assembly', true, /^(?!.*\/not-used\/).*\/.*.\.(JPG|png|jpe?g|svg)$/)
        ,'images/portfolio/layout/pages/assembly'
	);   

    let _knownFor = [
        '± 25 verschillende kunststoffen'
        ,'± 50 verschillende Staalsoorten'
        ,'± 25 soorten Non-Ferro (Alu, koper, brons en messing)'
    ];

  return (
      <>
          <div className="page">
              <HeaderSection title={`${title}`} mediaHeight={'450px'} imageArray={_imageArray} showInfo={false} logo={false} />

              <div className="page-content">
                  <CompanyStory title={`Materialen`}>
                      <P>
                          Met de realisatie van allerlei projecten is in de loop der jaren een behoorlijk portfolio aan materialen en gereedschappen bij ons ontstaan.
                      </P>
                      <P>
                          Wij hebben mogen werken met een breed assortiment aan materialen in de categorieën: Staal, RVS, Non-Ferro, Kunststoffen en Composieten.
                      </P>
                      <P>
                          Graag laten we u een kleine greep zien uit ons assortiment van ‘exoten’ en de daarbij behorende toepassingen:
                      </P>

                  </CompanyStory>
                      {/* Material grid */}
                      {MaterialenLijst(GetExcelFile(`/materialen.xlsx`))}

                      <CompanyStory line={false}>
                        <P>
                            <div style={{padding: '10px'}}>
                                <List title={"Wij zijn bekend met:"}  style={{paddingLeft: '10px'}}
                                    // subTitle={"sadf"}
                                    items={[..._knownFor
                                    ]}
                                />
                            </div>

                        </P>
                      </CompanyStory>

                  <hr/>

              </div>
          </div>
      </>
  );
};

function MaterialenLijst(object, _columsToSkip = []) {
    let rows = [];
    let row = <></>;
    let rowIndex = 0, rowItemIndex = 0, columnIndex = 0;
    const _skipColumn = _columsToSkip;//[1,2]

    const _style_rowHeader = `uppercase py-2 inline-block break-words`;
    const _style_columnItem = `text-black font-normal inline-block height100 [&:nth-child(1n+2)]:border-l-4 border-gray-200 border-solid border-0 align-text-bottom max-sm:px-1 px-2 py-1.5 hyphens-auto break-words`;
    const _style_row = `bg-white overflow-hidden max-sm:text-[0.55rem] odd:bg-gray-100 first-of-type:bg-red-700 first-of-type:text-white material-list grid gap-4 justify-center items-center`;

    if (object != null) {
      // console.log("Object exists");
      object.forEach(_row => {
          rowItemIndex = 0;
          columnIndex = 0;
          row = <></>;

          _row.forEach(item => {
              // Check if the column needs to be skipped
              if (_skipColumn.length > 0 && columnIndex == _skipColumn.find((i) => i == columnIndex)) {
                  // For now dont do anything
              } else {

                if (rowIndex == 0) {
                  // Header
                  row = <>{row}<th className={`${_style_rowHeader} `}>{item}</th></>;
                } else {
                  // Item
                  row = <>{row}<div className={`${_style_columnItem}`}>{item}</div></>;// className='border-4 border-indigo-600'
                }
                rowItemIndex++;
              }

              columnIndex++;
          });

          rows.push(<div className={`${_style_row} grid-cols-4 ${rowItemIndex}`}>{row}</div>)
          rowIndex++;
      });
    } else {
      console.log("Object is null");
    }

    // return <div className='tabel-auto rounded-lg'>{rows}</div>;
    return <>
      <ContainerWrapper>
          <Container>
              <ContainerContent noPadding>
              
              <div className='tabel-auto rounded-lg'>{rows}</div>
              
              </ContainerContent>
          </Container>
      </ContainerWrapper>
    </>;
}


