import React, { useEffect, useState } from "react";
import CardItem from "components/layout/cards/CardItem";
// import CardItem from './CardItem';
import "components/layout/cards/Cards.css";
import { Dimensions, List } from "components/layout/text/TextComponents";
import { P } from "styling/Text";

var textTitle = "Draaibanken"; //Portfolio

var before = "";
var after = "";

function TitleText(string) {
  return before + string + after;
}

export function CollapsibleText(props) {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
    console.log(open);
  }

  let _return = <>
  <button onClick={handleClick} className={`text-collapsible ${open ? 'active' : ''}`}>{props.title} <i className={`fas fa-chevron-down text-collapsible-arrow ${open ? 'active' : ''}`}></i></button>
  <div style={{height: `${open ? '100%' : '0'}`}} className={`text-collapsible-content ${open ? 'active' : ''}`}>
    {props.children}
  </div>
  </>;

  return _return;
}

export const MachineCardsCategories = {
  draaibanken: [
        <CardItem
        title={TitleText("Doosan Lynx2100LSYB met barfeeder en cobot")}
        type='machines'
        src="/images/machines/lathes/doosan-lynx.png"
        text={<>
        <List title={undefined} 
        items={[
          `Draaidiameter 300 mm`
          ,`Doorlaat 65 mm`
          ,`Draailengte 510 mm`
          ,`Aangedreven gereedschap`
          ,`Clamping chuck Hainbuch`
          ,`Subspindel`
        ]}
        />
        </>}
        zoom={80}
      />,
      <CardItem
        title={TitleText("TOS SUS80")}
        type='machines'
        src="/images/machines/lathes/tos_sus80.jpg"
        text={<>
          <List title={undefined} 
          items={[
             `Draaidiameter: 800mm`
            ,`Draailengte: 3500mm`
            ,`Doorlaat: 80mm`
          ]}
          />
          </>}
      />
      ,<CardItem
        title={TitleText("SPINNER TC600 SMCY met barfeeder")}
        type='machines'
        src="/images/machines/lathes/spinner-tc600-smcy2.jpg"
        text={<>
        <CollapsibleText title={'Hoofdspindel'}>
        <List title={undefined} bulletOutside={true}
        items={[
          `Technology Direct drive`
          ,`Speed 4.000 rpm`
          ,`Power 34 kW`
          ,`Torque 325 Nm`
          ,`C-Axis 0,0001°`
          ,`Bar capacity 65 mm`
          ,`Max. turning diameter 300 mm`
          ,`Clamping cylinder Holow clamping cylinder`
        ]}
        />
        </CollapsibleText>

        <CollapsibleText title={'Sub spindle'}>
        <List title={undefined}  bulletOutside={true}
        items={[
           `Technology Direct drive`
          ,`Speed 6.000 rpm`
          ,`Power 15 kW`
          ,`Torque 96 Nm`
          ,`C-Axis 0,0001°`
          ,`Bar capacity 42 mm`
          ,`Clamping cylinder Holow clamping cylinder`
        ]}
        />
        </CollapsibleText>

        <CollapsibleText title={'Tool carrier I (X/Y/Z)'}>
        <List title={undefined}  bulletOutside={true}
        items={[
           `Technology Tool turret, 16 stations`
          ,`Tool taper BMT45`
          ,`Driven tools 6.000 rpm, 16,5 kW, 35 Nm`
          // ,<Dimensions x={275} y={90 + '(+50/-40mm)'} z={700} />
          ,`X-Axis 275 mm`
          ,`Y-Axis 90 mm (+50/-40mm)`
          ,`Z-Axis 700 mm`
        ]}
        />
        </CollapsibleText>

        <List title={undefined} 
          items={[
             `Spindel: 11,5Kw - 2500 omw/min`
          ]}
          />
        
        </>}
      />
      ,<CardItem
        title={TitleText("Padovani Labor E255 Teach")}
        type='machines'
        src="/images/machines/lathes/labor-e255.jpg"
        text={<>
          <List title={undefined} 
          items={[
             `Spindel: 24Kw - 4000 omw/min`
            ,`Draaidiameter 500mm`
            ,`Draailengte 1500mm`
            ,`Doorlaat 75mm`
          ]}
          />
          </>}
      />
      ,<CardItem
        title={TitleText("Lionet LT-05 | CNC Draaibank")}
        type='machines'
        src="/images/machines/lathes/lionet-lt05_2.jpg"
        text={<>
          <List title={undefined} 
          items={[
             `Draaidiameter 230mm`
            ,`Draailengte 350mm`
            ,`Doorlaat 45mm`
          ]}
          />
          </>}
        // zoom={100}
      />
      ,<CardItem
        title={TitleText("Harrison 280CNC")}
        type='machines'
        src="/images/machines/lathes/harrison.jpg"
        text={<>
          <List title={undefined} 
          items={[
             `Draaidiameter 280mm`
            ,`Draailengte 600mm`
            ,`Doorlaat 35mm`
          ]}
          />
          </>}
      />
      ,<CardItem
        title={TitleText("MYFORD Super-7")}
        type='machines'
        src="/images/machines/lathes/myford-super.jpg"
        // text={window.basicText}
      />
    ],

    freesbanken: [
      <CardItem
        title={TitleText("Fehlmann Picomax 80 CNC")}
        type='machines'
        src="/images/machines/mills/fehlmann.jpg"
        // text={<>
        //   <List title={undefined} 
        //   items={[
        //      `4e as`
        //     ,<Dimensions x={1500} y={640} z={500} />
        //   ]}
        //   />
        //   </>}
      />
      ,<CardItem
        title={TitleText("Cinch-Mill30")}
        type='machines'
        src="/images/machines/mills/cinch-mill.jpg"
        text={<>
          <List title={undefined} 
          items={[
             `4e as`
            ,<Dimensions x={1500} y={640} z={500} />
          ]}
          />
          </>}
      />
      ,<CardItem
        title={TitleText("Finetech GTX-620 | 5 Assig")}
        type='machines'
        src="/images/machines/mills/finetech_gtx_620.jpg"
        text={<>
          <List title={undefined} 
          items={[
            <Dimensions x={620} y={420} z={400} />
          ]}
          />
          </>}
          zoom={80}
      />
      ,<CardItem
        title={TitleText("Aciera F4")}
        type='machines'
        src="/images/machines/mills/aciera_f4_v.jpg"
        text={<>
          <List title={undefined} 
          items={[
            <Dimensions x={400} y={200} z={400} />
          ]}
          />
          </>}
          zoom={50}
      />
    ],

    meetkamer: [
      <CardItem
        title={TitleText("Tesa Micro hite 3D")}
        type='machines'
        src="/images/machines/meetkamer/tesa-micro-hite-3d.png"
        zoom={50}
        text={<>
          <P>Coördinatenmeetmachine (CMM)</P>
          </>}
      />
      ,<CardItem
        className="cards_item_wide sm:col-span-2 max-sm:first"
        // title={TitleText("Two Test")}
        type='machines'
        src="/images/machines/meetkamer/meetkamer-overview.jpg"
        text={<>
          <P noBreak>In een geconditioneerde ruimte worden de producten uiteindelijk gemeten.<br/> En op verzoek met meetrapport meegeleverd.</P>
          </>}
        fill
        // zoom={50}
      />
      ,<CardItem
        title={TitleText("Hauser H 602")}
        type='machines'
        src="/images/machines/meetkamer/hauser2.jpg"
        text={<>
          <P>Profielprojector</P>
          </>}
        // fill
        zoom={50}
      />
      ,<CardItem
        title={TitleText("LANDTEK SRT-6210S")}
        type='machines'
        src="/images/machines/meetkamer/srt-6210_2.jpg"
        text={<>
          <P>Ruwheidsmeter</P>
          </>}
        zoom={50}
      />
    ],
    
    productie: [
      <CardItem
        title={TitleText("Servotap tapautomaat")}
        type='machines'
        src="/images/machines/production/taparm.png"
        text={<>
          <List title={undefined} 
          items={[
            'Schroefdraad: M3 t/m M24'
          ]}
          />
          </>}
          zoom={50}
          fill
      />
      ,<CardItem
        title={TitleText("Brootspers 16ton")}
        type='machines'
        src="/images/machines/production/brootspers.jpg"
        text={<>
          <List title={undefined} 
          items={[
            'Spiebanen: 2mm tot 25mm'
          ]}
          />
          </>}
        zoom={100}
        fill
      />
      ,<CardItem
        title={TitleText("Astoba / Meyer & Burger")}
        type='machines'
        src="/images/machines/production/astoba.jpg"
        text={<><P>
          Universele draai- en freesmachine
        </P></>}
        // text={<>
        //   <List title={undefined} 
        //   items={[
        //     'Spiebanen: 2mm tot 25mm'
        //   ]}
        //   />
        //   </>}
        zoom={100}
        fill
      />
      ,<CardItem
        title={TitleText("Jakobsen 824")}
        type='machines'
        src="/images/machines/production/jacobsen.jpg"
        text={<><P>
          Vlakslijpmachine
        </P></>}
        // text={<>
        //   <List title={undefined} 
        //   items={[
        //     'Spiebanen: 2mm tot 25mm'
        //   ]}
        //   />
        //   </>}
        zoom={100}
        fill
      />
    ],
  };

export default function MachineCards(props) {
  const style = {
    animationName: "from-bottom, from-transparent",
    animationDuration: "1.2s",
  };

  const categories = MachineCardsCategories;

  return (
    <div className="cards">
      <h1>{props.title ? props.title : textTitle}</h1>
      <div className="cards_container">
      <h2>{props.underTitle ? props.underTitle : ''}</h2>
        <div className="cards_wrapper">
          <div className="cards_items" style={style}>
            {categories[props.title.toLowerCase()]}
          </div>
        </div>
      </div>
    </div>
  );
}

{
  /* <CardItem
              title={TitleText("Laswerk")}
              src="/images/machines/lathes/tos-sus80.jpg"
              label="Product"
              text={window.basicText}
              date="asd"
              path="/factuur"
            /> */
}

/*
`Hoofdspindel:
        Spindel: 11,5Kw - 2500 omw/min
        Doorlaat: ø 77mm
        
        Draaidiameter: ø 500mm
        Draailengte T.D.C. 1500mm
        Over de slede: ø 325mm
        
        Snelwissel beitelhouder
        Besturing: Siemens 805 v.4`

`Hoofdspindel:
        Spindel: 24Kw - 4000 omw/min
        Doorlaat: ø 65mm
        Klauwplaat: ø 250mm
        Spantang opname: ø 60mm
        C-As
        
        Direct wegmeet systeem op x/z as
        Stafaanvoer unit: ø 60mm  L=1200mm
        Productvang unit: op hoofd/subspil
        
        Subspindel:
        Spindel: 11Kw - 6000 omw/min
        Doorlaat: ø 42mm
        Klauwplaat: ø 160mm
        C-As
        
        Product uitwerper
        
        Hogedruk koeling
        Aangedreven gereedschappen
        Revolver met 32 gereedschap posities
        Meetarm
        
        X as: 275mm
        Y as: + 50 - 40mm
        Z as: 700mm
        
        Besturing: Siemens 840 D-SL`
*/