import React from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

// to={props.path}
function CardItem(props) {

  var _style = props.style;
  
  var _type = props.type ? props.type : '';

  var _showBackground = (props.background == undefined && props.background != false) ? true : false;

  if (props.zoom != undefined)
  {
    var zoom = -(props.zoom / 6);
      //props.zoom = props.zoom / 100;
      _style = {width: props.zoom + "%", marginLeft: 'auto', marginRight: 'auto'};//{transform: "scale("+ (props.zoom / 100) + ")"}; , left: zoom + "%"
    }

  const _background = <>
  {/* Background | Only show this image if zoomed in */}
  {props.zoom ? <>
    <img
    className={`cards_item_img cards_item_img_background ${_type}`}
    alt={`Image`}
    src={window.publicURL + props.src}
    style={{opacity: '0.1'}}
  />
  </> : undefined}

  </>;

  const _image = props.src ? <img
  className={`cards_item_img ${_type}`}
  alt={`Image`}
  src={window.publicURL + props.src}
  style={{..._style}}
/> : undefined;


const _video = props.video ? <video controls
className={`cards_item_img ${_type}`}
// alt={`Image`}
src={window.publicURL + props.video} type="video/mp4"
style={{..._style}}
>

</video> : undefined;


const _youtube = props.youtube ?  <iframe 
// width="560" height="315" 
className={`cards_item_img ${_type}`}
src={`${props.youtube}`} 
title="YouTube video player" 
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;" 

// referrerpolicy="strict-origin-when-cross-origin"
// SECURE_REFERRER_POLICY = "no-referrer-when-downgrade"
  allowFullScreen={true}
mozallowfullscreen="mozallowfullscreen" 
msallowfullscreen="msallowfullscreen" 
oallowfullscreen="oallowfullscreen" 
webkitallowfullscreen="webkitallowfullscreen"
/>

 : undefined;

// const _youtube = props.youtube ?  <iframe 
// className={`cards_item_img ${_type}`}
// width="420" height="315"
// src={props.youtube}>
// </iframe>  : undefined;

// ${_showBackground ? 'blur' : '' }
  
return (
  <>
  
    <div key={props.key} className={`cards_item ${_type} ${props.className}`} title="Image" style={props.style}> 
      
      <div className={`cards_item_link bg-white ${_showBackground ? 'link_background' : '' } ${props.fill ? 'height100' : '' }`}>       
        
        <figure className={`cards_item_pic_wrap ${_type}`} data-category={props.label}>
        <div className={`cards_item_label_wrap ${_type}`}>
          {props.label ? <h2 className={`cards_item_label ${_type}`}>{props.label}</h2> : ''}
          </div>
          <div>

          {props.imageLink ? <a href={props.imageLink} target='blank' className=''>
            <>
            {_background}
            {_image}
            {_video}
            {_youtube}
            </>
          </a> :
          <>
            {_background}
            {_image}
            {_video}
            {_youtube}
          </>
          }
          
          </div>
        </figure>

        {/* Info */}
        {(props.title || props.text || props.subText) ? <>
          <div className={`cards_item_info ${_type}`}>
          {props.title ? <h5 className={`cards_item_title ${_type}`}>{props.title}</h5> : ''}
          {props.title == undefined || props.text == undefined ? "" : <hr/>}
          {/* <hr/> */}
          {props.text ? <p className={`cards_item_text ${_type}`}>{props.text ? props.text : ""}</p> : ''}
          {props.subText ? <p className={`cards_item_subText ${_type}`}>{props.subText ? props.subText : ""}</p> : ''}
          </div>
        </> : ''}

      </div>

    </div>
  </>
);
}

export default CardItem;
