import React, { useEffect, useState, Component } from 'react';
import HeaderSection from '../layout/pages/home/HeaderSection';
import CompanyStory from '../layout/text/CompanyStory';
import { importAll, importAllArray } from 'images';


import { Container, ContainerContent, ContainerWrapper, ContainerGroup } from 'components/layout/containers/Containers';
import { P } from 'styling/Text';
import { GetExcelFile, InfoTabel, TabelRow } from 'components/tools/excel';

const story = undefined;
const title = `Nabehandelingen`;

export default function Treatments () {

	const _imageArray = importAllArray(
		require.context('../../../public/images/portfolio/layout/pages/assembly', true, /^(?!.*\/not-used\/).*\/.*.\.(JPG|png|jpe?g|svg)$/)
        ,'images/portfolio/layout/pages/assembly'
	);   

  return (
      <>
          <div className="page">
              <HeaderSection title={`${title}`} mediaHeight={'450px'} imageArray={_imageArray} showInfo={false} logo={false} />

              <div className="page-content">
                  <CompanyStory title={`Nabehandelingen`} noPaddingBottom noMarginBottom>
                      <P>
                          Door de jaren heen hebben we een breed netwerk van partners met diverse specialiteiten op het gebied van conservering en corrosiebescherming opgebouwd waardoor we in staat zijn om de klant volledig te kunnen ontzorgen.
                      </P>
                      <P>
                          Onze partners zijn zorgvuldig geselecteerd op kwaliteit en flexibiliteit (doorlooptijden, etc.,) en wij hebben ons tot op zekere hoogte verdiept in de diverse specialiteiten waardoor we in de meeste gevallen direct advies kunnen geven over de gewenste gespecialiseerde toepassingen.
                      </P>
                      <P noBreak>
                          Wij zijn bekend met de een breed scala aan ‘nabehandelingen’ die wij graag willen toelichten:
                      </P>

                    </CompanyStory>
                    {/* Material grid */}
                    {InfoTabel(GetExcelFile(`/conserveringen.xlsx`), [2,3])}

                    <CompanyStory line={false}>
                        <P>
                            Wij beperken ons niet tot het aanbieden van de ‘Nabehandelingen’ die in deze lijst staan, daarom nodigen we u graag uit om naar de mogelijkheden te vragen en aan te geven welke specifieke oplossing u zoekt voor uw project.
                        </P>
                    </CompanyStory>


                  <hr/>

              </div>
          </div>
      </>
  );
};



