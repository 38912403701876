import CardItem from "components/layout/cards/CardItem";
import HeaderSection from "components/layout/pages/home/HeaderSection";
import React from "react";
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import "../../App.css";
import "../layout/pages/contact/Contact.css";
import "../layout/grids/Grids.css";
import {ContainerWrapper, Container, ContainerContent} from "../layout/containers/Containers";
import Socialmedia, { SOLink } from "components/layout/interacion/socialmedia/Socialmedia";

import {entities} from 'entities.js';
import { importAllArray } from "images";
import ContactForm from "components/layout/interacion/forms/ContactForm";
import { P } from "styling/Text";

const contactPeopleTitleParts = ["Contactpers", "oon", "onen"];
const showEmployeePhone = true;
const showEmployeeEmail = true;

var before = "";
var after = "";

Date.prototype.getWeek = function(){
    // We have to compare against the first monday of the year not the 01/01
    // 60*60*24*1000 = 86400000
    // 'onejan_next_monday_time' reffers to the miliseconds of the next monday after 01/01

    var day_miliseconds = 86400000,
        onejan = new Date(this.getFullYear(),0,1,0,0,0),
        onejan_day = (onejan.getDay()==0) ? 7 : onejan.getDay(),
        days_for_next_monday = (8-onejan_day),
        onejan_next_monday_time = onejan.getTime() + (days_for_next_monday * day_miliseconds),
        // If one jan is not a monday, get the first monday of the year
        first_monday_year_time = (onejan_day>1) ? onejan_next_monday_time : onejan.getTime(),
        this_date = new Date(this.getFullYear(), this.getMonth(),this.getDate(),0,0,0),// This at 00:00:00
        this_time = this_date.getTime(),
        days_from_first_monday = Math.round(((this_time - first_monday_year_time) / day_miliseconds));

    var first_monday_year = new Date(first_monday_year_time);

    // We add 1 to "days_from_first_monday" because if "days_from_first_monday" is *7,
    // then 7/7 = 1, and as we are 7 days from first monday,
    // we should be in week number 2 instead of week number 1 (7/7=1)
    // We consider week number as 52 when "days_from_first_monday" is lower than 0,
    // that means the actual week started before the first monday so that means we are on the firsts
    // days of the year (ex: we are on Friday 01/01, then "days_from_first_monday"=-3,
    // so friday 01/01 is part of week number 52 from past year)
    // "days_from_first_monday<=364" because (364+1)/7 == 52, if we are on day 365, then (365+1)/7 >= 52 (Math.ceil(366/7)=53) and thats wrong

    return (days_from_first_monday>=0 && days_from_first_monday<364) ? Math.ceil((days_from_first_monday+1)/7) : 52;
}

var iconPhone = <><i className="fas fa-phone-alt"></i></>;
var iconMail = <><i className="fas fa-envelope"></i></>;

const today = new Date();
const startHolidays = new Date(today.getFullYear(), 11 - 2);
const isHolidayMonth = today.getMonth() >= startHolidays.getMonth();

const weekDay = today.getWeek();
const _holidayWeek = 30;
const isHolidayWeek = weekDay >= _holidayWeek - 3 && weekDay < _holidayWeek + 1;

//global.config.companyInfo.businessHours
function GetObjectKeys(array, tag, getKey, className, textLeftRight, index) {
  const left = textLeftRight != undefined ? textLeftRight[0] : "";
  const right = textLeftRight != undefined ? textLeftRight[1] : "";

  var _i = Object.keys(array).length;

  return <>
      {/* 👇️ iterate object KEYS */}
      {Object.keys(array).map(key => {
        _i--;
        return (parse(`
          <${tag} ${className ? (`className=${className}`) : ''} key=${key} style="textTransform: capitalize">
              ${left}
              ${index != undefined ? global.config.companyInfo.businessHours[key][index] : (getKey ? key : global.config.companyInfo.businessHours[key])}
              ${right}
          </${tag}>
          ${_i != 0 ? '<hr style="opacity:25%;" />' : ""}
        `));
      })}
    </>
}

function GetBusinessHours(array, className, className2, tag) {
var _i = Object.keys(array).length;
  return <>
      {/* 👇️ iterate object KEYS */}
      {Object.keys(array).map(key => {
        _i--;
        return (parse(`
          <${tag} className="${className} ${className2}" key=${key}>
              ${global.config.companyInfo.businessHours[key][0]} <small style="opacity:70%;">t/m</small> ${global.config.companyInfo.businessHours[key][1]}
          </${tag}>
          ${_i != 0 ? ('<hr className="' + className +'-line" style="opacity:25%;" />') : ""}
        `));
      })}
    </>
}

function TitleText(string) {
  return before + string + after;
}

export function CardsContainer(props) {
  return (
    <>
      <div className="cards">
        <h1>{props.title}</h1>
        <div className="cards_container">
          <div className="cards_wrapper">
            <div className="cards_items_two">{props.cards}</div>
          </div>
        </div>
      </div>
    </>
  );
}

export class BusinessNumbersWrapper extends React.Component {
  render() {
    return <div className="business-numbers-wrapper" style={this.props.style}>{this.props.children}</div>
  }
}

export class BusinessNumbers extends React.Component {
  render() {
    return <div className="business-numbers" style={this.props.style}>{this.props.children}</div>
  }
}

function Contact() {

  const _makeContact = <>
    <h1>Neem contact met ons op</h1>
			
    <div className="margin-center" style={{ width: '70%' }}>
        
      <ContactForm />
  
    </div>

    <br />
    <hr />
  </>;

  let _soLink = '';//<SOLink style={{float: 'right'}} place='linkedin' color='#0e76a8' to={`${global.config.companyInfo.socials.linkedin}`}/>;

	const _imageArray = importAllArray(
		require.context('../../../public/images/portfolio/layout/pages/contact', true, /^(?!.*\/not-used\/).*\/.*.\.(JPG|png|jpe?g|svg)$/)
    ,'images/portfolio/layout/pages/contact'
  );
	
	// Create the contact cards
	let _cards = [];
	let _key = 0;
	entities.employees.contactPage.forEach(item => {
		// console.log("Contact", item.phoneNumbers);
		const _phone = showEmployeePhone ? `${iconPhone} ${item.phoneNumbers}` : "";
		const _mail = showEmployeeEmail ? <>{iconMail} <a className="contact-info-link" href={`mailto:${item.emails}`}>{item.emails}</a></> : "";
    //${_phone} 
		// console.log(item.logo());
		_cards.push(
		<CardItem
		key={_key}
			title={<>{TitleText(item.name)}{_soLink}</>}
			src={`${item.logo}`}//'/images/portfolio/placeholder/placeholder-product-01.jpg'
			label={item.role}
        // text={
        //   <div style={{wordBreak: "break-all"}}>
        //     {_mail}
        //   </div>
        // }
        text={<>
        {_soLink}
        </>}
			style={{animationName: "from-left, from-transparent", animationDuration: "0.75s"}}
			type='contact'
		/>);

		_key++;
	});
 
  const cards = (
    <>
		{_cards}
    </>
  );

  const textTitle = `${contactPeopleTitleParts[0]}${(entities.employees.contactPage.length < 2) ? contactPeopleTitleParts[1] : contactPeopleTitleParts[2]}`;
  const contactCards = <CardsContainer cards={cards} title={textTitle} />;

  const size = 'width="100%", height="300px"';
  // const googleMaps = `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2417.526670491363!2d5.145498815931351!3d52.70463853028149!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c8af62864b2663%3A0x66615af2ead1afaf!2sSchreuder%20Metaal%20%26%20Techniek!5e0!3m2!1snl!2snl!4v1670249131522!5m2!1snl!2snl&zoom=1" ${size} style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`;
  const googleMaps = `<div class="mapouter"><div class="gmap_canvas"><iframe class="gmap_iframe" width="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=600&amp;height=300&amp;hl=en&amp;q=schreuder metaal en techniek&amp;t=&amp;z=8&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe><a href="https://formatjson.org/word-counter">Word Counter</a></div><style>.mapouter{position:relative;text-align:right;width:100%;height:300px;}.gmap_canvas {overflow:hidden;background:none!important;width:100%;height:300px;}.gmap_iframe {height:300px!important;}</style></div>`;
  // const googleMaps = '<div class="mapouter"><div class="gmap_canvas"><iframe class="gmap_iframe" width="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=600&amp;height=600&amp;hl=en&amp;q=schreuder metaal en techniek&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe><a href="https://formatjson.org/">format json</a></div><style>.mapouter{position:relative;text-align:right;width:100%;height:600px;}.gmap_canvas {overflow:hidden;background:none!important;width:100%;height:600px;}.gmap_iframe {height:600px!important;}</style></div>';

  return (
    <>
      <div className="page">
      {/* <HeaderSection video={window.publicURL + '/videos/video-1.mp4'} showInfo={false} logo={false} /> */}
      <HeaderSection mediaHeight={'30vh'} imageArray={_imageArray} showInfo={false} logo={false} />
        <div className="page-content">
          {/* <div ><h1 style={{textAlign: 'center'}}>Neem contact met ons op</h1></div> */}
          <div className="contact-page-info-wrapper" style={{animationName: "from-top, from-transparent", animationDuration: "1s"}}>
            <div className="contact-page-info">
              <ContainerWrapper className={"width85 pwidth100"} style={{ paddingLeft: "2%" }}>
              <h1 className="container-title">Neem contact met ons op</h1>
                <Container>
                  <ContainerContent>
                  <br/>    
                    <h3>{iconPhone} <a className="font-light contact-info-link" href={`mailto:${entities.companies.SchreuderMetaalEnTechniek.phoneNumbers}`}>{entities.companies.SchreuderMetaalEnTechniek.phoneNumbers}</a></h3>
                        <br/>
                        <h3>{iconMail} <a style={{wordBreak: "break-all"}} className="font-light contact-info-link" href={`mailto:${entities.companies.SchreuderMetaalEnTechniek.emails}`}>{entities.companies.SchreuderMetaalEnTechniek.emails}</a></h3>
                        <br/>
                    {/* <div style={{backgroundColor: "red", height: '100px'}}> */}
                      {/* style={{backgroundColor: "red", position: 'absolute', width: '100%', height: '100px', left: 0, bottom: 0, margin: 0}} */}
                      
                    {/* </div> */}
                  </ContainerContent>
                </Container>
                
			          <h1 className="container-title">Wij zijn ook te vinden op</h1>
                <Container>
                  <ContainerContent style={{padding: "0"}}>
                    <Socialmedia />
                  </ContainerContent>
                </Container>
              </ContainerWrapper>

              {/* <div ><p style={{textAlign: 'center'}}>Neem contact met ons op</p></div> */}

              <ContainerWrapper className={"width100 from-right-play"} style={{minWidth: "318px"}}>
                <h1 style={{textAlign: 'center'}}>Openingstijden</h1>
                <Container>
                  <ContainerContent>
                    <div className="column-wrapper">
                      <div className="column">
                        {GetObjectKeys(global.config.companyInfo.businessHours, "h2", true, "business-time business-days font-light")}
                        {/* {GetBusiness(global.config.companyInfo.businessHours, "business-hours", "h2")} */}
                        {/* {GetWorkingDays()} */}
                      </div>
                      <div className="column column-right">
                        {GetBusinessHours(global.config.companyInfo.businessHours, "business-time", "business-hours font-light", "h2")}
                        {/* {GetObjectKeys(global.config.companyInfo.businessHours, "h2", false, "business-hours", ["t/m",""], 1)} */}
                      </div>
                    </div>
          
                  </ContainerContent>
                  {isHolidayMonth ? <h3 style={{padding: "2.5% 5%", backgroundColor: "var(--accent)", color: "white", textAlign: 'center'}}>Week 52 zijn wij gesloten</h3> : ''}
                  {isHolidayWeek ? <h3 style={{padding: "2.5% 5%", backgroundColor: "var(--accent)", color: "white", textAlign: 'center'}}>Week {_holidayWeek} zijn wij gesloten</h3> : ''}
                </Container>
              </ContainerWrapper>
          </div>
        </div>

      {/* Contact cards */}
      {contactCards}

			{/*- Location */}
			<ContainerWrapper>
			<h1 className="container-title">Waar u ons kunt bezoeken</h1>
				<Container>
					<ContainerContent>
						<div className="location-wrapper">

						<div className="address-wrapper" style={{gridArea: "location"}}>
							{/* <div className="address-background"></div> */}
							<div style={{float: "left"}} className="address-text">
							<h1>Adres</h1>
							<P>
								{global.config.companyInfo.address.street} {global.config.companyInfo.address.number}
								{/* <br/> */}
								, {global.config.companyInfo.address.zipCode} {global.config.companyInfo.address.city}
                      </P>
                      <P>
                       <b>Vertegenwoordigers op afspraak</b>
                      </P>
							</div>

							{/* Route button */}
							<a href={`https://maps.google.com/maps/dir//Schreuder+Metaal+%26+Techniek+Perenmarkt+8+1681+PG+Zwaagdijk/@52.7045955,5.1475445,8z/data=!4m5!4m4!1m0!1m2!1m1!1s0x47c8af62864b2663:0x66615af2ead1afaf`} target="_blank">
								<div style={{float: "right"}} className='button'>
									Route beschrijving
								</div>
							</a>
					
						</div>

						<div className="address-maps" style={{gridArea: "maps"}}>
							{parse(googleMaps)}
						</div>

						<div className="address-img-wrapper" style={{gridArea: "image"}}>
							{/* <div className="address-img-inner"> */}
							<img style={{borderRadius: '0 0 0 10px'}}
								className="address-img"
								alt="Image"
								src={`${window.publicURL}/images/company/company-front-01.jpg`}
								/>
							{/* </div> */}
						</div>
						</div>
					</ContainerContent>
				</Container>
			</ContainerWrapper>

      <br/>
      <hr/>

      {/* {_makeContact} */}
            

			{/* <br/> */}

			{/*- Btw & Kvk */}
			<ContainerWrapper>
			<h1 className="container-title">Onze overige informatie</h1>
				<Container>
					<ContainerContent>
						<BusinessNumbersWrapper>
							<BusinessNumbers>
								<h3>Btw &nbsp;&nbsp;<il className="font-light">{global.config.companyInfo.btw}</il></h3>
							</BusinessNumbers>
							<BusinessNumbers>
								<h3>Kvk &nbsp;&nbsp;<il className="font-light">{global.config.companyInfo.kvk}</il></h3>
							</BusinessNumbers>
							{/* <BusinessNumbers>
								<h3>IBAN &nbsp;&nbsp;<il className="font-light">{global.config.companyInfo.iban}</il></h3>
							</BusinessNumbers> */}
							</BusinessNumbersWrapper>
					</ContainerContent>
				</Container>
			</ContainerWrapper>
          </div>
      </div>
    </>
  );
}

export default Contact;


/*
<div className="business-numbers">

                <h2><i className="fas fa-phone-alt" /> Btw Nummer</h2>
                <i><h2>{global.config.companyInfo.btw}</h2></i>
                <br/>
                <h2><i className="fas fa-envelope" /> KvK Nummer</h2>
                <i><h2>{global.config.companyInfo.kvk}</h2></i>

              </div>
              <div className="business-numbers">
                
                <h2><i className="fas fa-phone-alt" /> Telefoonnummer</h2>
                <i><h2>{global.config.companyInfo.phoneNumberMain}</h2></i>
                <br/>
                <h2><i className="fas fa-envelope" /> E-Mailadres</h2>
                <i><h2>{global.config.companyInfo.emailMain}</h2></i>

              </div>
*/



const bcp = <>
<Container>
<ContainerContent>
  <BusinessNumbersWrapper>
    <BusinessNumbers>
      {/* <h3>Btw Nummer</h3>
      <i><h3>{global.config.companyInfo.btw}</h3></i> */}
      <h3>{iconPhone} <i>{global.config.companyInfo.phoneNumberMain}</i></h3>
    </BusinessNumbers>
    <BusinessNumbers>
      {/* <h3>KvK Nummer</h3>
  <i><h3>{global.config.companyInfo.kvk}</h3></i> */}
    <h3>{iconMail} <i>{global.config.companyInfo.emailMain}</i></h3>
    </BusinessNumbers>
  </BusinessNumbersWrapper>
</ContainerContent>
</Container>

<div ><p style={{textAlign: 'center'}}>Neem contact met ons op</p></div>

<Container>
<ContainerContent>
  <BusinessNumbersWrapper>
    <BusinessNumbers>
      {/* <h3>Btw Nummer</h3>
  <i><h3>{global.config.companyInfo.btw}</h3></i> */}

  <h3>Btw <i>{global.config.companyInfo.btw}</i></h3>
    </BusinessNumbers>
    <BusinessNumbers>
      {/* <h3>KvK Nummer</h3>
  <i><h3>{global.config.companyInfo.kvk}</h3></i> */}
  
  <h3>Kvk <i>{global.config.companyInfo.kvk}</i></h3>
    </BusinessNumbers>
  </BusinessNumbersWrapper>
</ContainerContent>
</Container></>
;