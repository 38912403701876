import React from 'react'
import CardItemHorizontal from './CardItemHorizontal';
import './CardsHorizontal.css';
import { P } from 'styling/Text';



var before = '';
var after = '';

function TitleText(string) {
  return before + string + after
}

export default function CardsHorizontal(props) {
	let cards = props.cards;
  let textTitle = props.title == false ? '' : (props.title || 'Een selectie van onze projecten');//Portfolio
  var _phone = window.isPhoneSize;

  var _columns = true;

	if (props.projects) {
		// console.log(props.projects[0].owner);
		cards = [];
    let _i = 0;
		props.projects.forEach(project => {
    // console.log(project);
			cards.push(
				<CardItemHorizontal
                _key={String(_i)}
                title={TitleText(`${project.name}`)}
                src={`${project.image}`}// Image
                imageArray={project.imageArray}// Image
                label={project.label ? project.label : ''}
                text={project.description}
                date={`30-01-2023`}
                path={`/factuur`}
                thumbnailsBottom={project.thumbnailsBottom}
				        logo={global.config.companyMedia.logo}
                vertical={project.vertical}
              />
			);
      _i++;
		});
	}

	if (!cards) {
		cards = <>
			<CardItemHorizontal
      key={'3451'}
                title={TitleText(`Extrudervijzel volledig RVS`)}
                src={`/images/portfolio/placeholder/placeholder-product-01.jpg`}
                // label={`Product`}
                text={
                  <>
                  <P>
                  Voor een klant in de voedingsindustrie hebben wij een set extrudervijzels gemaakt om zeer fijne puree te maken.
                  </P>
                  <P>
                  Dit betreft een wormvijzel en een huis met zeefgaten die volledig uit rvs 316 zijn gemaakt en volgens een plasma hardingsproces zijn gehard.
                  </P>
                  <P>
                  Dit geheel is vervolgens nageslepen en op elkaar pasgemaakt.
                  </P>
                  </>
                }
                date={`30-01-2023`}
                path={`/factuur`}
				        logo={global.config.companyMedia.logo}
              />
              <CardItemHorizontal
              key={'3452352'}
                title={TitleText(`Bronzen Giektop`)}
                src={`/images/portfolio/projects/bronzen-giektop/Eindresultaat Bronzen Giektop.jpg`}
                label={`Eindresultaat Bronzen Giektop`}
                text={<>
                <P>
                  Voor een klant in luxe jachtbouw hebben wij diverse opdrachten om zoveel als mogelijk exacte replica`s te maken van antieke beslagdelen.
                  <br/>
                  Hier afgebeeld een deel van een bronzen giektop waarvan er maar één deel aanwezig was.
                  <br/>
                  Er moest een tweede deel volgens de eisen van de klant gemaakt worden.
                  <br/>
                  </P>
                  <P>
                  Normaal gesproken moet alles glad en strak zijn, hier werd het tegendeeel gevraagd:
                  <i>"Kunnen jullie het zo maken dat het eruitziet als een onderdeel van honderd jaar oud?"</i>
                  <br/>
                  Ik denk dat we er goed in geslaagd zijn om dit te realiseren.
                </P>
                </>}
                date={`30-01-2023`}
                path={`/portfolio`}
				        // logo={`/logo/placeholder_logo2.png`}
				        logoInImage='true'
				// mirror={`true`}
              />
		</>;
	}

    return (
      <div key={props._key} className={`cards horizontal w-4/5 mx-auto`}>
        <h1>{textTitle}</h1>
        <div className={`cards_container horizontal width100 pwidth100`} style={{margin: '0 auto 7%'}}>
          <div className={`cards_wrapper horizontal`}>
            <div className={`cards_items ${_phone || _columns ? 'horizontal' : '!grid-cols-2'}`}>
            {/* horizontal */}
              {cards}
            </div>
          </div>
        </div>
      </div>
    );
  }


  // if (!cards) {
	// 	cards = <>
	// 		<CardItemHorizontal
  //     key={'3451'}
  //               title={TitleText(`Product Voorbeeld 1 (Breed Logo)`)}
  //               src={`/images/portfolio/placeholder/placeholder-product-01.jpg`}
  //               label={`Product`}
  //               text={window.basicText + window.basicText + window.basicText + window.basicText}
  //               date={`30-01-2023`}
  //               path={`/factuur`}
	// 			logo={global.config.companyMedia.logo}
  //             />
  //             <CardItemHorizontal
  //             key={'3452352'}
  //               title={TitleText(`Product Voorbeeld 2 (Vierkant Logo)`)}
  //               src={`/images/portfolio/placeholder/placeholder-product-02.jpg`}
  //               label={`Product`}
  //               text={window.basicText + window.basicText + window.basicText + window.basicText}
  //               date={`30-01-2023`}
  //               path={`/portfolio`}
	// 			        logo={`/logo/placeholder_logo2.png`}
	// 			        logoInImage='true'
	// 			// mirror={`true`}
  //             />
	// 	</>;
	// }