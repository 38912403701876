import React, { useEffect, useState, Component } from 'react';
import HeaderSection from '../layout/pages/home/HeaderSection';
import CompanyStory from '../layout/text/CompanyStory';
import { importAll, importAllArray } from 'images';


import { Container, ContainerContent, ContainerWrapper, ContainerGroup } from 'components/layout/containers/Containers';
import { P } from 'styling/Text';
import { GetExcelFile, InfoTabel } from 'components/tools/excel';
import { List } from 'components/layout/text/TextComponents';
import { ArrayToHTMLList } from 'components/tools/arrays';

const story = undefined;
const title = `Specialiteiten`;

export default function Specialities () {

	const _imageArray = importAllArray(
		require.context('../../../public/images/portfolio/layout/pages/assembly', true, /^(?!.*\/not-used\/).*\/.*.\.(JPG|png|jpe?g|svg)$/)
        ,'images/portfolio/layout/pages/assembly'
	);   

    let _list = [];
    let _listTitle = '';
    let _obj = GetExcelFile(`/conserveringen.xlsx`, 2);
    let _i = 0;

    if (_obj != null) {
        _obj.forEach(row => {
            if (_i == 0) {
                _listTitle = row;
            } 
            else if (row.length != 0) {
                _list.push(row);
            }
            _i++;
        });
    }

  return (
      <>
          <div className="page">
              <HeaderSection title={`${title}`} mediaHeight={'450px'} imageArray={_imageArray} showInfo={false} logo={false} />

              <div className="page-content">
                  <CompanyStory title={`Specialiteiten`} noPaddingBottom noMarginBottom>
                      <P>
                          Er zijn naast de Conservering en Nabehandelingen van materialen ook vele andere specialiteiten die ons in de afgelopen jaren gevraagd zijn aan te bieden en waarin wij ontzorgen.
                      </P>
                      <P>
                          We hebben ons kunnen uitbreiden met vele ‘Specialiteiten’ en bijzondere bewerkingen, en ook hier (zie <a href='/services-overige-treatments'>Nabehandelingen</a>) hebben we de juiste partners om ons te ondersteunen bij een aantal gespecialiseerde bewerkingen. 
                      </P>
                      <P>
                          Wat kunnen wij nog meer betekenen naast ons uitgebreide aanbod van werkzaamheden:
                      </P>

                  </CompanyStory>

                      {/* Material grid */}
                      {InfoTabel(GetExcelFile(`/conserveringen.xlsx`, 1), [2,3])}


                    <CompanyStory line={false}>
                        <div style={{padding: '10px'}}>
                            <List title={_listTitle}  style={{paddingLeft: '10px'}}
                                // subTitle={"sadf"}
                                items={[..._list
                                ]}
                            />
                        </div>
                    </CompanyStory>
                  <hr/>

              </div>
          </div>
      </>
  );
};

