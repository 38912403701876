import Navbar from 'components/layout/navbar/Navbar';
import {render} from "react-dom";
import {BrowserRouter as Router, HashRouter, Switch, Route} from 'react-router-dom';
import './App.css';
import './index.css';
import './styling/Text.css';
import './styling/Layout.css';
import './components/layout/containers/containers.css'

import Home from 'components/pages/Home';
import MissieVisie from 'components/pages/MissieVisie';
import About from 'components/pages/About';
import Machinepark from 'components/pages/Machinepark';
import Klanten from 'components/pages/Klanten';
import Vacatures from 'components/pages/Vacatures';
import Contact from 'components/pages/Contact';
import WorkerInterface from 'components/pages/WorkerInterface';

import Footer from 'components/layout/footer/Footer';
import React from 'react';
import Assembly from 'components/pages/Assembly';
import Production from 'components/pages/Production';
import { CheckCollapsibleText } from 'styling/Text';
import Projects from 'components/pages/Projects';
import News from 'components/pages/News';
import Manufacturing from 'components/pages/Manufacturing';
import Materials from 'components/pages/Materials';
import Treatments from 'components/pages/Treatments';
import Specialities from 'components/pages/Specialities';

window.basicText = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.";

window.maxWidth = 1220;

class App extends React.Component {
	constructor() {
		super();
		// Set document variables
		document.title = global.config.companyInfo.name;
	
		window.baseLocation = document.location.pathname;
		
		window.publicURL = process.env.PUBLIC_URL;

		window.phoneWidth = 850;

		window.isPhoneSize = (window.innerWidth <= window.phoneWidth ? true : false);
		window.addEventListener("resize", () => {
			window.isPhoneSize = (window.innerWidth <= window.phoneWidth ? true : false);

		});
		// Update Global variables
		global.config.websiteInfo.creationDate = new Date().getFullYear();
	}

	
	render() {
		const _pathName = `${window.location.origin}`;
		
		return (<>
			{/* <HashRouter basename={process.env.PUBLIC_URL}> */}
			<Router>
				<Navbar />
				<Switch>
					<Route path={`/`} exact component={Home} />
					<Route path={`/missievisie`} exact component={MissieVisie} />
					<Route path={`/about-company`} exact component={About} />
					<Route path={`/about-projects`} exact component={Projects} />

					<Route path={`/501`} component={Home} />
					<Route path={`/404`} component={Home} />

					{/* Services */}
					<Route path={`/services-assembly`} exact component={Assembly} />
					<Route path={`/services-production`} exact component={Production} />
					<Route path={`/services-manufacturing`} exact component={Manufacturing} />

					<Route path={`/services-overige-materials`} exact component={Materials} />
					<Route path={`/services-overige-treatments`} exact component={Treatments} />
					<Route path={`/services-overige-specialities`} exact component={Specialities} />
					
					<Route path={`/nieuws-items`} exact component={News} />
					<Route path={`/nieuws-vacatures`} exact component={Vacatures} />

					<Route path={`/machinepark`} exact component={Machinepark} />
					<Route path={`/klanten`} exact component={Klanten} />
					<Route path={`/vacatures`} exact component={Vacatures} />
					<Route path={`/contact`} exact component={Contact} />
					<Route path={`/workerinterface`} exact component={WorkerInterface} />

					<Route path='*' component={Home} element={<Home />} />
				</Switch>
				{/* <CheckCollapsibleText /> */}
				<Footer />
			</Router>
			{/* </HashRouter> */}
			</>);
			
	}
}

export default App;